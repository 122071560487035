































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { MessageController, AttendanceController } from '@/services/request.service'
import moment from 'moment'
import debounce from 'lodash/debounce'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import FlexTooltip from '@/components/FlexTooltip.vue'
import DetailModal from '@/components/DetailModal.vue'
import { createPagination } from '@/constant/constant'
import Guide from '@/components/Guide.vue'
import { getGuideViewStatus, getIntroShow } from '@/utils/utils'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
    DetailModal,
    Guide,
  },
})
export default class takeLeave extends Vue {
  private detailVisible: boolean = false
  private filter: any = {
    leaveTime: [],
  }
  private loading: boolean = false
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private leaveData: Array<any> = []
  private requestInfo: any = {
    title: '',
    infoList: [],
    id: undefined,
    originData: {},
  }
  private guideVis: any = false

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }
  private get studentName(): string {
    let student = this.$store.state.currentStudent
    return `${student.enName} ${student.name}`
  }
  @Watch('studentId', { immediate: true })
  public onStudentChange(newVal): void {
    if (newVal) {
      this.getData()
    }
  }
  private get columns(): Array<Object> {
    return [
      {
        dataIndex: 'leaveTime',
        key: 'leaveTime',
        title: this.$t('takeLeave.leaveTime'),
        ellipsis: true,
        scopedSlots: { customRender: 'leaveTime' },
      },
      {
        dataIndex: 'reason',
        key: 'reason',
        title: this.$t('takeLeave.reason'),
        ellipsis: true,
        scopedSlots: { customRender: 'reason' },
      },
      {
        dataIndex: 'displayName',
        key: 'approver',
        title: this.$t('takeLeave.approver'),
        ellipsis: true,
        scopedSlots: { customRender: 'approver' },
      },
      {
        dataIndex: 'status',
        key: 'status',
        title: this.$t('common.status'),
        scopedSlots: { customRender: 'status' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }
  private get showChild(): boolean {
    return this.$route.name !== 'takeLeave'
  }

  private get dataSource(): any {
    return this.leaveData.map(item => {
      let startTime = moment(item.startTime).format('YYYY.MM.DD HH:mm')
      let endTime = moment(item.endTime).format('YYYY.MM.DD HH:mm')
      let leaveTime = `${startTime}~${endTime}`
      return {
        ...item,
        leaveTime,
      }
    })
  }

  private getData(page = { pageSize: this.pagination.defaultPageSize, current: 1 }): void {
    if (this.loading) return
    this.loading = true
    this.leaveData = []
    AttendanceController.getApplicationList(
      page.current,
      page.pageSize,
      this.studentId,
      this.filter.leaveTime[0] ? this.filter.leaveTime[0].valueOf() : undefined,
      this.filter.leaveTime[1] ? this.filter.leaveTime[1].valueOf() : undefined
    )
      .then(res => {
        this.leaveData = res.data.items.map(item => {
          let startTime = moment(item.startTime).format('YYYY.MM.DD HH:mm')
          let endTime = moment(item.endTime).format('YYYY.MM.DD HH:mm')
          let leaveTime = `${startTime}~${endTime}`
          return {
            ...item,
            leaveTime,
          }
        })
        this.pagination.total = res.data.totalItem
      })
      .catch(err => console.log(err))
      .finally(() => (this.loading = false))
  }

  private addLeave(): void {
    this.$router
      .push({
        name: 'leaveEditor',
        query: { type: 'add', studentId: this.studentId + '', studentName: this.studentName },
      })
      .catch(err => {})
  }

  private editLeave(item): void {
    this.$router
      .push({
        name: 'leaveEditor',
        query: {
          type: 'edit',
          leaveId: item.leaveApplicationId,
          studentId: this.studentId + '',
          studentName: this.studentName,
        },
      })
      .catch(err => {})
  }

  private getTimeString(timeItem): string {
    let day = moment(timeItem.startTime).format('YYYY[.]MM[.]DD')
    let start = moment(timeItem.startTime).format('HH:mm')
    let end = moment(timeItem.endTime).format('HH:mm')
    return `${day} ${start}~${end}`
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
    this.getData(pagination)
  }

  private mounted() {
    let status = getGuideViewStatus()
    const introShow = getIntroShow() || {}
    if (!status.takeLeavePC && introShow.pc) {
      this.guideVis = true
    }
  }

  private recall(leaveId): void {
    AttendanceController.retrieveApplication(leaveId)
      .then(res => {
        this.$message.success(this.$tc('common.recallSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => this.refresh())
  }

  private validateRecall(item): boolean {
    let current = moment()
    let target = moment(item.startTime.date)
    if (['declined', 'retrieved'].includes(item.status)) {
      return false
    } else {
      return true
    }
    // if(current.isSame(target,'day')){
    //   let hoursix = moment().hour(6).minute(0).second(0).milliseconds(0)
    //   let hourtwelve = moment().hour(12).minute(0).second(0).milliseconds(0)
    //   return current.isBefore(hoursix,'hour') || (current.isBefore(hourtwelve,'hour') && !item.startTime.isAm)
    // } else if(current.isAfter(target,'day')){
    //   return false
    // } else {
    //   return true
    // }
  }

  private viewDetails(singleRequest): void {
    this.requestInfo = {
      title: this.$t('takeLeave.leaveDetail'),
      id: singleRequest.leaveApplicationId,
      infoList: this.dealDetailData(singleRequest),
      originData: singleRequest,
    }
    this.detailVisible = true
  }

  private dealDetailData(data): any {
    let nameItem = {
        key: 'name',
        label: this.$t('takeLeave.leavePerson'),
        value: '',
      },
      statusItem = {
        key: 'leaveStatus',
        label: this.$t('takeLeave.leaveStatus'),
        value: '',
      },
      // typeItem = {
      //   key: 'leaveType',
      //   label: this.$t('takeLeave.leaveType'),
      //   value: ''
      // },
      // durationItem = {
      //   key: 'leaveDuration',
      //   label: this.$t('takeLeave.leaveDuration'),
      //   value: '',
      // },
      leaveReasonItem = {
        key: 'leaveReason',
        label: this.$t('takeLeave.reason'),
        value: '',
        visRow: 6,
      },
      rejectReasonItem = {
        key: 'rejectReason',
        label: this.$t('takeLeave.rejectReason'),
        value: '',
        visRow: 6,
      },
      applyItem = {
        key: 'apply',
        label: this.$t('takeLeave.apply'),
        value: '',
        visRow: 6,
      },
      approverItem = {
        key: 'approver',
        label: this.$t('takeLeave.approver'),
        value: '',
      },
      timeItem = {
        key: 'leaveTime',
        label: this.$t('takeLeave.leaveTime'),
        value: '',
      },
      attachments = {
        key: 'attachments',
        label: this.$t('message.attachments'),
        value: '',
      }
    if (data) {
      nameItem.value = this.studentName
      // durationItem.value = `${data.durationInDays}${this.$t('common.days')}`
      leaveReasonItem.value = data.reason
      approverItem.value = data.auditor
      rejectReasonItem.value = data.declineReason
      statusItem.value = this.$tc(`takeLeave.${data.status}`)
      // typeItem.value = this.$t(`takeLeave.${data.type}`)
      timeItem.value = data.leaveTime
      applyItem.value = data.approveReply
      attachments.value = data.attachment
    }
    return [
      nameItem,
      statusItem,
      // typeItem,
      timeItem,
      // durationItem,
      leaveReasonItem,
      approverItem,
      (data || {}).status === 'declined' ? rejectReasonItem : -1,
      (data || {}).status === 'approved' ? applyItem : -1,
      attachments,
    ].filter(item => item !== -1)
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}
