var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"leave-editor",attrs:{"layout":"horizontal","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol,"form":_vm.leaveEditor,"autocomplete":"nope"}},[_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('takeLeave.student')}},[_c('div',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'student',
        {
          initialValue: _vm.student.studentName,
          rules: [
            {
              required: 'true',
            } ],
        } ]),expression:"[\n        'student',\n        {\n          initialValue: student.studentName,\n          rules: [\n            {\n              required: 'true',\n            },\n          ],\n        },\n      ]"}],staticClass:"name-style"},[_c('span',[_vm._v(_vm._s(_vm.student.studentName))])])]),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t("common.startTime"),"required":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'startTime',
        {
          validateFirst: true,
          rules: [
            {
              required: 'true',
              message: _vm.$t('tips.selectTime'),
            },
            {
              validator: _vm.validateTime,
              start: true,
            } ],
        } ]),expression:"[\n        'startTime',\n        {\n          validateFirst: true,\n          rules: [\n            {\n              required: 'true',\n              message: $t('tips.selectTime'),\n            },\n            {\n              validator: validateTime,\n              start: true,\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":{
        format: 'HH:mm',
      },"format":"YYYY-MM-DD HH:mm","disabledDate":_vm.disabledDate},on:{"change":function () { return _vm.onTimeChange(true); }}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('common.endTime'),"required":""}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'endTime',
        {
          validateFirst: true,
          rules: [
            {
              required: true,
              message: _vm.$t('tips.selectTime'),
            },
            {
              validator: _vm.validateTime,
              start: false,
            } ],
        } ]),expression:"[\n        'endTime',\n        {\n          validateFirst: true,\n          rules: [\n            {\n              required: true,\n              message: $t('tips.selectTime'),\n            },\n            {\n              validator: validateTime,\n              start: false,\n            },\n          ],\n        },\n      ]"}],staticStyle:{"width":"100%"},attrs:{"showTime":{
        format: 'HH:mm',
      },"format":"YYYY-MM-DD HH:mm","disabledTime":_vm.disabledDateTime,"disabledDate":_vm.disabledDate},on:{"change":function () { return _vm.onTimeChange(false); }}})],1),_c('a-form-item',{staticClass:"form-item",attrs:{"label":_vm.$t('takeLeave.reason')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'reason',
        {
          rules: [
            {
              required: true,
              message: _vm.$t('takeLeave.pleaseEnterReason'),
            } ],
        } ]),expression:"[\n        'reason',\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('takeLeave.pleaseEnterReason'),\n            },\n          ],\n        },\n      ]"}],attrs:{"autoSize":{
        minRows: 4,
        maxRows: 8,
      },"placeholder":_vm.$t('takeLeave.pleaseEnterReason')}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('message.attachments')}},[_c('upload',{attrs:{"option":{ maxNum: 5, maxSize: 100 }},on:{"loadingChange":function (status) { return (_vm.uploading = status); }},model:{value:(_vm.attachments),callback:function ($$v) {_vm.attachments=$$v},expression:"attachments"}})],1),_c('a-form-item',{attrs:{"label":" ","colon":false}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.takeLeave}},[_vm._v(_vm._s(_vm.$t('common.submit')))]),_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }