






































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DropDownController, AttendanceController } from '@/services/request.service'
import moment from 'moment'
import { fileUploader } from '@/services/qiniu.service'
import Upload from '@/components/Upload.vue'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    Upload,
  },
})
export default class LeaveEditor extends Vue {
  private leaveEditor: any
  private canLeave: boolean = false
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private student: any = {
    studentId: undefined,
    name: undefined,
  }
  private leaveId: any
  private teachers: Array<any> = []
  private loading: boolean = false
  private uploading: any = false
  private attachments: any = []

  private get leaveTypes(): any {
    return [
      {
        key: 'holiday',
        label: this.$t('takeLeave.holiday'),
      },
      {
        key: 'illness',
        label: this.$t('takeLeave.illness'),
      },
      {
        key: 'exams',
        label: this.$t('takeLeave.exams'),
      },
      {
        key: 'others',
        label: this.$t('takeLeave.others'),
      },
    ]
  }

  private get currentStudent(): any {
    return this.$store.state.currentStudent || {}
  }

  // private disabledDate(current): any {
  //   return current && current < moment().startOf('day')
  // }

  private getLeaveData(): any {
    AttendanceController.getLeaveApplication(this.leaveId)
      .then(res => {
        let data = {
          startTime: moment(res.data.startTime),
          endTime: moment(res.data.endTime),
          type: res.data.type,
          reason: res.data.reason,
        }
        this.attachments = res.data.attachment.map(item => {
          return {
            name: item.resourceName,
            uid: item.resourceId,
            url: item.resourceUrl,
            status: 'done',
            percent: 0,
          }
        })
        this.leaveEditor.setFieldsValue(data)
      })
      .catch(err => console.log(err))
  }

  private takeLeave(): void {
    this.loading = true
    this.leaveEditor.validateFields((err: any, values: any) => {
      if (err) {
        this.loading = false
        return
      } else {
        let condition = {
          leaveApplicationId: this.leaveId,
          type: 'personal',
          startTime: values.startTime.valueOf(),
          endTime: values.endTime.valueOf(),
          reason: values.reason,
          studentId: this.student.studentId,
          resourceIds: this.attachments.map(item => item.uid),
        }
        AttendanceController.saveLeaveApplication(condition)
          .then(res => {
            this.canLeave = true
            this.$route.meta.refresh = true
            this.$message.success(this.$tc('common.saveSuccess'))
            this.$router.push({ name: 'takeLeave' })
          })
          .catch(err => console.log(err))
          .finally(() => (this.loading = false))
      }
    })
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private disabledDate(current): any {
    let currentSchoolYear = this.$store.state.currentSchoolYear
    let { enterDate } = this.currentStudent
    const nine = moment(current)
      .hour(9)
      .minute(0)
      .second(0)
    const seven = moment(current)
      .hour(7)
      .minute(40)
      .second(0)
    if (!Object.keys(currentSchoolYear).length) return false
    return (
      current &&
      (current < moment(Math.max(currentSchoolYear.startTime, enterDate || 0)).startOf('day') ||
        current > moment(currentSchoolYear.endTime).startOf('day'))
    )
  }

  private disabledDateTime(current) {
    return {
      disabledHours: () => {
        return this.range(0, 24)
          .splice(21, 23)
          .concat(this.range(0, 24).splice(0, 7))
      },
      disabledMinutes: () => {
        if (current && current.hour() === 7) {
          return this.range(0, 60).splice(0, 41)
        } else {
          return []
        }
      },
    }
  }

  private range(start, end): any {
    const result = [] as any
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  private onTimeChange(start): void {
    let otherTime = this.leaveEditor.getFieldValue(start ? 'endTime' : 'startTime')
    if (!otherTime) return
    this.$nextTick(() => {
      if (start) {
        this.leaveEditor.validateFields(['endTime'], { force: false }, (err: any, values: any) => {
          if (err) {
            return
          }
        })
      } else {
        this.leaveEditor.validateFields(
          ['startTime'],
          { force: false },
          (err: any, values: any) => {
            if (err) {
              return
            }
          }
        )
      }
    })
  }

  private validateTime(rule, value, cb): void {
    let otherTime = this.leaveEditor.getFieldValue(rule.start ? 'endTime' : 'startTime')
    if (!otherTime) {
      cb()
      return
    }

    if ((value.isAfter(otherTime) && rule.start) || (value.isBefore(otherTime) && !rule.start)) {
      cb(this.$t(rule.start ? 'takeLeave.startLaterThanEnd' : 'takeLeave.endEarlierThanStart'))
    } else {
      cb()
    }
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    this.student = {
      studentId: to.query.studentId,
      studentName: to.query.studentName,
    }
    this.$nextTick(() => {
      this.leaveEditor.setFieldsValue({ student: this.student.studentName })
    })
    this.leaveId = to.query.leaveId
    if (to.query.type === 'edit') {
      this.getLeaveData()
    }
  }

  private beforeCreate(): void {
    this.leaveEditor = this.$form.createForm(this)
  }
}
